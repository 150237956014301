import React from 'react'
import { Container, Row, Col } from 'reactstrap'

import SectionTitle from '../components/SectionTitle'
import SEO from '../components/SEO'

import './datenschutz.scss'

const title = 'Datenschutz | Kipa Nachrichtentechnik'

const PrivacyPolicyPage = () => {
  return (
    <>
      <SEO title={title} />
      <div className="PrivacyPolicy">
        <Container>
          <Row>
            <Col md="10">
              <SectionTitle title="Datenschutzerklärung" />
              <p>
                Die Kipa Nachrichtentechnik legt großen Wert auf den Schutz
                Ihrer personenbezogener Daten. Soweit die Nutzung unserer
                Website nicht ohne Angabe personenbezogener Daten möglich ist,
                erfolgt eine Verwendung Ihrer personenbezogenen Daten (wie bspw.
                Name, Anschrift, E-Mail-Adresse) stets freiwillig und nur mit
                Ihrer Zustimmung. Dies gilt auch für eine Weitergabe Ihrer
                personenbezogenen Daten an Dritte.
              </p>
              <p>
                Die uns von Ihnen im Rahmen von &ldquo;Anfragen&rdquo;
                bekanntgegebene E-Mail-Adresse und Ihre Nachricht werden von uns
                ausschließlich zum Zweck der Bearbeitung Ihrer Anfrage
                verwendet.
              </p>
              <p>
                Wir haben entsprechend den gesetzlichen Vorgaben für die
                elektronische Kommunikation und des Datenschutzes die
                vorgeschriebenen technischen und organisatorischen Maßnahmen
                getroffen.
              </p>
              <p>
                Sie haben jederzeit das Recht auf Auskunft, Richtigstellung oder
                Löschung Ihrer personenbezogenen Daten. Zum Zwecke des
                technischen Betriebes der Webseite werden IP-Adresse, Datum,
                Uhrzeit und betrachtete Seiten beim Besuch der Webseite erhoben.
                Diese dienen ausschließlich der Nachvollziehbarkeit im
                Fehlerfall und der Fehlerbehebung aus technischer Sicht. Diese
                Daten werden nicht an Dritte weiter gegeben.
              </p>
              <SectionTitle title="Google Analytics" />
              <p>
                Unsere Website (<a href="https://www.kipa.at/">www.kipa.at</a>)
                benutzt Google Analytics, einschließlich der Funktionen von
                Universal Analytics, einen Webanalysedienst der Google Inc.
                (&ldquo;Google&rdquo;).
                <br />
                <br />
                <strong>
                  Wir verwenden dabei keine Werbefunktionen und kein
                  geräteübergreifendes Tracking Ihrer Daten mittels User-ID.
                </strong>
              </p>
              <p>
                Google Analytics verwendet sogenannte &ldquo;Cookies&rdquo;,
                Textdateien, die auf Ihrem Computer gespeichert werden und die
                eine Analyse der Benutzung der Website durch Sie ermöglichen.
                Die durch das Cookie erzeugten Informationen über Ihre Benutzung
                dieser Website werden in der Regel an einen Server von Google in
                den USA übertragen und dort gespeichert.
              </p>
              <p>
                <strong>IP-Anonymisierung (IP-Masking):</strong> Aufgrund der
                nicht abschließend geklärten Rechtslage zur Verwendung von
                Analysetools mit vollständigen IP-Adressen, wird auf unserer
                Website Google Analytics mit der Erweiterung
                &ldquo;_anonymizeIp()&rdquo; verwendet. Ihre IP-Adresse wird
                daher von Google vor der weiteren Verwendung noch innerhalb von
                Mitgliedstaaten der Europäischen Union oder in anderen
                Vertragsstaaten des Abkommens über den Europäischen
                Wirtschaftsraum gekürzt und somit nur mehr anonymisiert
                verwendet. Nur in Ausnahmefällen wird die volle IP-Adresse an
                einen Server von Google in den USA übertragen und dort gekürzt.
              </p>
              <p>
                In unserem Auftrag wird Google diese Informationen benutzen, um
                Ihre Nutzung der Website auszuwerten, um Reports über die
                Websiteaktivitäten zusammenzustellen und um weitere mit der
                Websitenutzung und der Internetnutzung verbundene
                Dienstleistungen uns gegenüber zu erbringen.
              </p>
              <p>
                Die im Rahmen von Google Analytics von Ihrem Browser
                übermittelte (verkürzte) IP-Adresse wird nicht mit anderen Daten
                von Google zusammengeführt. Sie können die Speicherung der
                Cookies durch eine entsprechende Einstellung Ihrer
                Browser-Software verhindern; wir weisen Sie jedoch darauf hin,
                dass Sie in diesem Fall gegebenenfalls nicht sämtliche
                Funktionen dieser Website vollumfänglich werden nutzen
                können.&nbsp;Sie können darüber hinaus die Erfassung der durch
                das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen
                Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung
                dieser Daten durch Google verhindern, indem Sie das unter dem
                folgenden Link (
                <a
                  rel="noopener noreferrer"
                  href="http://tools.google.com/dlpage/gaoptout?hl=de"
                  target="_blank"
                >
                  http://tools.google.com/dlpage/gaoptout?hl=de
                </a>
                ) verfügbare Browser-Plugin herunterladen und installieren.
              </p>
              <p>
                Nähere Informationen zu Nutzungsbedingungen und Datenschutz von
                Google Analytics finden Sie unter{' '}
                <a
                  rel="noopener noreferrer"
                  href="https://www.google.de/intl/de/policies/"
                  target="_blank"
                >
                  https://www.google.de/intl/de/policies/
                </a>
                .
              </p>
              <SectionTitle title="Cookies" />
              <p>
                Nachfolgend finden Sie eine Auflistung der von uns gesetzten
                zustimmungspflichtigen Cookies.
              </p>
              <table className="table">
                <tbody>
                  <tr>
                    <td>
                      <strong>Name des Cookies</strong>
                    </td>
                    <td>
                      <strong>Funktion</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>_ga Speicherdauer (2 Jahre ab dem Aufruf)</p>
                      <p>_gid Speicherdauer (24 Stunden ab dem Aufruf)</p>
                      <p>_gat Speicherdauer (1 Minute ab dem Aufruf)</p>
                    </td>
                    <td>
                      <p>Google Analytics</p>
                      <span>
                        Diese Cookies werden verwendet zur Analyse der Anzahl
                        der Website-Besucher und welche Informationen diese dort
                        gesucht und welche Seiten wie oft aufgerufen wurden.
                        <br />
                        Klicken Sie{' '}
                        <a
                          rel="noopener noreferrer"
                          href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage"
                          target="_blank"
                        >
                          hier
                        </a>{' '}
                        um weitere Informationen zu
                        Google-Analytics-spezifischen Cookies zu erhalten
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>user-has-accepted-cookies (1 Jahr ab dem Aufruf)</p>
                    </td>
                    <td>
                      <p>
                        Dieser Cookie wird verwendet um zu überprüfen, ob Sie
                        Cookies von unserer Website akzeptieren.
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p>
                <br />
                Eine Nutzung unserer Angebote ist auch ohne Cookies möglich,
                abgesehen vom Speichern der Webcam Favoriten. Die meisten
                Browser sind so eingestellt, dass sie Cookies automatisch
                akzeptieren. Sie können das Speichern von Cookies jedoch
                deaktivieren oder Ihren Browser so einstellen, dass er Sie
                benachrichtigt, sobald Cookies gesendet werden.
              </p>
              <h3>Weitere Informationen</h3>
              <p>
                Für weitere allgemeine Information zu den hier angeführten
                Themen sowie Ihren eingangs erwähnten Rechten auf Auskunft,
                Richtigstellung und Löschung, stehen wir Ihnen gerne unter{' '}
                <a
                  rel="noopener noreferrer"
                  href="mailto:kipa@chello.at"
                  target="_blank"
                >
                  kipa@chello.at
                </a>{' '}
                zur Verfügung.
              </p>
            </Col>
          </Row>
        </Container>{' '}
      </div>
    </>
  )
}

export default PrivacyPolicyPage
